import React from 'react'
import HTML from '../assets/html.png'
import CSS from '../assets/css.png'
import JavaScript from '../assets/javascript.png'
import Node from '../assets/node.png'
import GitHub from '../assets/github.png'
import ReactLogo from '../assets/react.png'
import Tailwind from '../assets/tailwind.png'


const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-gradient-to-r from-[#00021d] via-[#1f054a] to-[#0e1029] text-[#d9daec]'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-[#F72585]'>Skills</p>
                <p className='py-4'>This is my tech stack!</p>
            </div>


            <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>

                {/*FIX THIS LATER USING OBJECT ARRAY FOR THE CARD CONTENT!!!!!!!!*/}

                <div className='shadow-md shadow-[#e34c25] hover:scale-110 duration-100'>
                    <img className='w-20 mx-auto' src={HTML} alt="HTML Logo" />
                    <p className='my-3'>HTML</p>
                </div>
                <div className='shadow-md shadow-[#0176bc] hover:scale-110 duration-100'>
                    <img className='w-20 mx-auto' src={CSS} alt="CSS Logo" />
                    <p className='my-3'>CSS</p>
                </div>
                <div className='shadow-md shadow-[#ffd600] hover:scale-110 duration-100'>
                    <img className='w-20 mx-auto' src={JavaScript} alt="JS Logo" />
                    <p className='my-3'>JavaScript</p>
                </div>
                <div className='shadow-md shadow-[#91d04d] hover:scale-110 duration-100'>
                    <img className='w-20 mx-auto' src={Node} alt="NodeJs Logo" />
                    <p className='my-3'>NodeJs</p>
                </div>

                <div className=' shadow-md shadow-[#00dafe] hover:scale-110 duration-100'>
                    <img className='w-20 mx-auto' src={ReactLogo} alt="React Logo" />
                    <p className='my-3'>React</p>
                </div>
                <div className='shadow-md shadow-[#eeeeee] hover:scale-110 duration-100'>
                    <img className='w-20 mx-auto' src={GitHub} alt="GitHub Logo" />
                    <p className='my-3'>GitHub</p>
                </div>

                <div className='shadow-md shadow-[#44a8b3] hover:scale-110 duration-100'>
                    <img className='w-20 mx-auto' src={Tailwind} alt="Tailwind CSS Logo" />
                    <p className='my-3'>Tailwind CSS</p>
                </div>
              </div>
        </div>
    </div>
  )
}

//#e34c25
export default Skills