import React from 'react'
import WorkImg from '../assets/coolvpn.png'

const Work = () => {
  return (
    <div name="work" className='w-full md:h-screen bg-gradient-to-r from-[#00021d] via-[#1f054a] to-[#0e1029] text-[#d9daec]'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-[#F72585]'>Projects</p>
                <p className='py-4'>A Showcase of my React.js Web Development Projects</p>
            </div>

            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

                {/*FIX THIS WITH THE ITEM ARRAY LATER*/}
                <div style={{backgroundImage: `url(${WorkImg})`}} className='shadow-md shadow-[#F72585] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    <div className=' opacity-0 group-hover:opacity-100 duration-300'>
                        <span className='text-2xl font-bold tracking-wider'>
                            Pure CSS UI
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://benjaminmichels-coolvpn.com/" target="_blank" rel='noopener noreferrer' >
                                <button className='text-center rounded-lg px-4 py-3 m-2 text-lg bg-white text-black'>Try Me</button>
                            </a>
                            <a href="https://github.com/Benjamin-Michels/CoolVpn" target="_blank" rel='noopener noreferrer'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 text-lg bg-white text-black'>Code</button>
                            </a>
                        </div>
                    </div>

                </div>
                {/*END OF FIRST */}

                <div style={{backgroundImage: `url(${WorkImg})`}} className='shadow-md shadow-[#F72585] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    <div className=' opacity-0 group-hover:opacity-100 duration-300'>
                        <span className='text-2xl font-bold tracking-wider'>
                            Pure CSS UI
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://benjaminmichels-coolvpn.com/" target="_blank" rel='noopener noreferrer' >
                                <button className='text-center rounded-lg px-4 py-3 m-2 text-lg bg-white text-black'>Try Me</button>
                            </a>
                            <a href="https://github.com/Benjamin-Michels/CoolVpn" target="_blank" rel='noopener noreferrer'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 text-lg bg-white text-black'>Code</button>
                            </a>
                        </div>
                    </div>

                </div>
                <div style={{backgroundImage: `url(${WorkImg})`}} className='shadow-md shadow-[#F72585] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    <div className=' opacity-0 group-hover:opacity-100 duration-300'>
                        <span className='text-2xl font-bold tracking-wider'>
                            Pure CSS UI
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://benjaminmichels-coolvpn.com/" target="_blank" rel='noopener noreferrer' >
                                <button className='text-center rounded-lg px-4 py-3 m-2 text-lg bg-white text-black'>Try Me</button>
                            </a>
                            <a href="https://github.com/Benjamin-Michels/CoolVpn" target="_blank" rel='noopener noreferrer'>
                                <button className='text-center rounded-lg px-4 py-3 m-2 text-lg bg-white text-black'>Code</button>
                            </a>
                        </div>
                    </div>

                </div>

                {/*###END###*/}
            </div>
        </div>
    </div>
  )
}

export default Work