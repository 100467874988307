import React from 'react'
import {useState} from 'react'
import {FaBars, FaTimes, FaCode, FaGithub, FaLinkedin} from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import {Link} from 'react-scroll'

const Navbar = () => {

    const [nav, setNav] = useState(false)
    const doClick =()=> setNav(!nav)

  return (
    <div className='fixed z-10 w-full h-[80px] flex justify-between items-center px-4 text-[#F72585]'>
        <div>
            <FaCode className='text-4xl hidden md:flex'/>
        </div>


        <ul className='hidden md:flex'>
            <Link to="home" smooth='true' duration={400}><li>Home</li></Link>
            <Link to="about" smooth='true' duration={400}><li>About</li></Link>
            <Link to="skills" smooth='true' duration={400}><li>Skills</li></Link>
            <Link to="work" smooth='true' duration={400}><li>Work</li></Link>
            <Link to="contact" smooth='true' duration={400}><li>Contact</li></Link>
        </ul>


        {/* drop down icon*/}
        <div onClick={doClick} className='md:hidden z-10'>
            {!nav ? <FaBars/> : <FaTimes/>}
        </div>

        {/* mobile nav section */}
        <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-black flex flex-col justify-center items-center'}>
            <li className='py-6 text-4xl'><Link onClick={doClick} to="home" smooth='true' duration={400}>Home</Link></li>
            <li className='py-6 text-4xl'><Link onClick={doClick} to="about" smooth='true' duration={400}>About</Link></li>
            <li className='py-6 text-4xl'><Link onClick={doClick} to="skills" smooth='true' duration={400}>Skills</Link></li>
            <li className='py-6 text-4xl'><Link onClick={doClick} to="work" smooth='true' duration={400}>Work</Link></li>
            <li className='py-6 text-4xl'><Link onClick={doClick} to="contact" smooth='true' duration={400}>Contact</Link></li>
        </ul>

        {/* Sidebar Icons */}
        <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] z-20 duration-300 border-2  border-[#F72585] rounded -md hover:shadow-[0px_0_35px_-6px_rgba(214,0,140,1)]'>
                    <a href="https://www.linkedin.com/in/benjamin-michels-523187219/" target="_blank" rel='noopener noreferrer' className='flex justify-between items-center w-full text-[#F72585]'>
                        LinkedIn <FaLinkedin size={30}/>
                    </a>
                </li>
            </ul>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px]  z-20 duration-300 border-2  border-[#F72585] rounded -md hover:shadow-[0px_0_35px_-6px_rgba(214,0,140,1)] '>
                    <a href="https://github.com/Benjamin-Michels" target="_blank" rel='noopener noreferrer' className='flex justify-between items-center w-full text-[#F72585]'>
                        GitHub <FaGithub size={30}/>
                    </a>
                </li>
            </ul>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] z-20 duration-300 border-2  border-[#F72585] rounded -md hover:shadow-[0px_0_35px_-6px_rgba(214,0,140,1)] '>
                    <a href="mailto:ben0michels@gmail.com" rel='noopener noreferrer' className='flex justify-between items-center w-full text-[#F72585]'>
                        E-Mail Me <HiOutlineMail size={30}/>
                    </a>
                </li>
            </ul>


        </div>

    </div>
  )
}

//style={{boxShadow: '1px 2px 9px #00ff0d', borderRadius:'50%'}}
export default Navbar