import React from 'react'
import {Link} from 'react-scroll'

const Main = () => {
  return (
    <div name='home' className='w-full h-screen bg-gradient-to-r from-[#00021d] via-[#1f054a] to-[#0e1029]'>

      <div className=' max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-[#d9daec]'>Hello, I'm</p>
        <h1 className='pb-2 font-[Dancing Script] text-4xl sm:text-7xl font-bold bg-gradient-to-r from-[#F72585] to-[#B5179E] inline-block text-transparent bg-clip-text'>Benjamin Michels</h1>
        <h2 className='pb-1 text-3xl sm:text-5xl font-bold bg-gradient-to-r from-[#d9daec] to-[#d9daec] inline-block text-transparent bg-clip-text'>A Front-End web developer</h2>
        <p className=' bg-gradient-to-r py-4 max-w-[700px] from-[#d9daec] to-[#d9daec] inline-block text-transparent bg-clip-text'>
        A passionate Front-End React Developer dedicated to crafting exceptional web experiences. With a keen eye for detail and a love for clean, efficient code, I strive to create visually stunning and, user-friendly websites that leave a lasting impression.
          </p>

        <div>
          <Link to="work" smooth='true' duration={400}><button className='text-[#F72585] group border-2 border-[#F72585] border-r-2 py-3 px-6 my-2 rounded-md flex items-center hover:shadow-[0px_0_35px_-6px_rgba(247,37,133,1)]'>View Work</button></Link> 
      
        </div>
      </div>
    </div>
  )
}

//bg-gradient-to-r from-[#270089] via-[#17014e] to-[#1a0033]
//bg-gradient-to-r from-[#ff1493] to-[#00FFFF] inline-block text-transparent bg-clip-text
export default Main