import React from 'react'

const contact = () => {
  return (
    <div name='contact' className='w-full h-screen flex justify-center items-center p-4 bg-gradient-to-r from-[#00021d] via-[#1f054a] to-[#0e1029] text-[#d9daec]'>
        <form method='POST' action="https://getform.io/f/1b2f7494-8191-45b1-9f92-675b10cb5a05" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-[#F72585]'>Let's Connect!</p>
                <p className='py-4'>I'm always open to new challenges and opportunities to work on exciting projects. If you're looking for a dedicated Front-End React Developer to bring your ideas to life, don't hesitate to get in touch. Let's collaborate and build something amazing together!</p>
            </div>
            <input className='p-2 text-[#1c1c1c]' type="text" placeholder='Name' name='name' />
            <input className='my-4 p-2 text-[#1c1c1c]' type="email" placeholder='Email' name='email' />
            <textarea className='p-2 text-[#1c1c1c]' name="message" rows="10" placeholder='Message'></textarea>
            <button className='text-white border-2 hover:bg-[#F72585] hover:border-[#F72585] hover:shadow-[0px_0_35px_-6px_rgba(247,37,133,1)] px-4 py-3 my-8 '>Let's Talk!</button>
        </form>
    </div>
  )
}

export default contact