import React from 'react'

const about = () => {
  return (
    <div name='about' className='w-full h-screen bg-gradient-to-r from-[#00021d] via-[#1f054a] to-[#0e1029] text-[#d9daec]'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full px-1 grid grid-cols-2 gap-8'>
                <div className='sm:text-right pb-8 pl-4'>
                    <p className='text-4xl font-bold inline border-b-4 border-[#F72585]'>About</p>
                </div>
                <div></div>
            </div>
            <div className='max-w-[1000px] w-full px-4 grid sm:grid-cols-2 gap-8'>
                <div className='sm:text-right text-4xl font-bold'>
                    <p>Hi, I'm Benjamin, welcome I hope you enjoy!</p>
                </div>
                <div className=' pt-3'>
                    <p>
                    As a Front-End React Developer, I specialize in turning ideas into reality by leveraging the power of React.js. I have a strong foundation in HTML, CSS, and JavaScript, which allows me to build responsive and interactive user interfaces. Whether it's creating pixel-perfect designs, optimizing performance, or ensuring cross-browser compatibility, I'm committed to delivering top-notch solutions.                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default about